<template>
	<v-card outlined class="dashboard--content-mobile pa-4 mt-2" ref="strollTo">

		<div class="mb-2 text-right">
			<v-icon v-if="loadingUpdateBasket" class="mr-1">fas fa-spinner fa-pulse</v-icon>
			<v-btn outlined @click="clearBasket()" class="order_form-clear_basket">
				CLEAR BASKET
			</v-btn>
		</div>

		<v-divider />

		<div v-for="(item, index) in products" :key="index">
			<div v-if="item.type === 'category'">
				<div :colspan="headers.length" :class="`id-${item.id}`" class="my-1">
					<div class="text-h6 " v-html="item.name" />
				</div>
			</div>
			<div v-else class="">
				<div style="justify-content: space-between; gap: 16px" class="d-flex align-start mt-2 mb-2"
					v-if="item.status.slug !== 'coming_soon'">

					<div class="d-flex mt-2" style="flex-direction: column;">
						<v-chip v-if="item.status.slug === 'coming_soon'" style="height: 20px" small color="orange"
							label dark class="px-1 py-0 mr-1">
							COMING SOON
						</v-chip>
						<div v-else>
							<v-chip style="height: 20px" small v-for="(a, i) in item.accents " :color="a.color.name"
								:key="i" label dark class="px-1 py-0 mr-1">
								{{ a.name }}
							</v-chip>
						</div>
						<div class="font-weight-500 black--text">
							{{ item.name }}
						</div>
						<v-spacer />
					</div>
				</div>


				<div style="gap: 10px; display: grid; grid-template-columns: repeat(3, 1fr)" class="align-center mt-2 mb-2"
					v-if="item.status.slug !== 'coming_soon'">
					<div @click="$refs.dialogProductImage.showDialog(item)">
						<v-img v-if="item.img" :src="item.img" max-width="60" max-height="60"
							class="ma-2 cursor--pointer" :alt="item.name" />
					</div>

					<div >
						<div class="table--product-prices" v-if="item.status.slug !== 'coming_soon'">
							<li v-for="(p, index) in item.prices" :key="index" :class="{
								active: index === activeDl
							}" class="text-no-wrap">
								{{
									index === activeDl
										? 'DL#' + index
										: ''
								}}

								<div class="price">
									<span
										:style="p.promotional_price ? 'text-decoration: line-through; margin-right: 8px' : ''">
										{{ p.price / 100 | euro }}
									</span>

									<span v-if="p.promotional_price">
										{{ p.promotional_price / 100 | euro }}
									</span>
								</div>
							</li>
						</div>
					</div>

					<div>
						<div class="font-weight-500 text-no-wrap text-right" v-if="item.status.slug !== 'coming_soon'">
							<span
								:style="item.retailPrice.promotional_price ? 'text-decoration: line-through; margin-right: 8px' : ''">
								{{ item.retailPrice.price / 100 | euro }}
							</span>

							<span v-if="item.retailPrice.promotional_price">
								{{ item.retailPrice.promotional_price / 100 | euro }}
							</span>
						</div>

						<block-product-quantity :item="item" :width="50" :key="`${item.id}-${item.total}`" class="my-2"
							style="justify-content: start !important;" />

						<div class="text-no-wrap mb-2 text-right" v-if="item.status.slug !== 'coming_soon'">TOTAL: {{ item.total /
							100 | euro }}</div>
					</div>
				</div>
			</div>
			<v-divider />
		</div>
		<dialog-product-image ref="dialogProductImage" />
	</v-card>
</template>
<script>
import BlockProductQuantity from "@/views/Dashboard/Blocks/BlockProductQuantity";
import DialogProductImage from "@/views/Dashboard/Dialog/DialogProductImage";

export default {
	name: 'BlockContentMobile',
	components: { BlockProductQuantity, DialogProductImage },
	computed: {
		loadingUpdateBasket() {
			return this.$store.getters['DASHBOARD_STORE/LOADING_UPDATE_BASKET'];
		},
		products() {
			return this.$store.getters['DASHBOARD_STORE/PRODUCTS'];
		},

		activeDl() {
			return this.$store.getters['DASHBOARD_STORE/ACTIVE_DL'];
		},

		goToId() {
			return this.$store.getters['DASHBOARD_STORE/GO_TO'];
		},
	},
	data: () => ({
		search: '',

		headers: [
			{
				text: 'Image', value: 'img', sortable: false, width: 90, align: 'center'
			},
			{
				text: 'Product', value: 'name', sortable: false, align: 'left',
			},
			{
				text: 'Retail Price (23% tax incl.)', value: 'retail', sortable: false, width: 130, align: 'right'
			},
			{
				text: 'Your Price (net)', value: 'your', sortable: false, width: 150, align: 'right',
			},
			{
				text: 'Quantity', value: 'quantity', sortable: false, width: 140, align: 'center',
			},
			{
				text: 'Total', value: 'total', sortable: false, width: 120, align: 'right',
			},
		]
	}),
	methods: {
		goToElement() {
			const el = this.$refs.strollTo;
			if (el) {
				const el = this.$el.getElementsByClassName('id-' + this.goToId)[0];
				if (el) {
					el.scrollIntoView({ behavior: 'smooth' });
				}
			}
		},
		clearBasket() {
			this.$swal({
				icon: 'warning',
				title: "Are you sure you want to clear the shopping cart?",
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'Cancel',
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('DASHBOARD_STORE/clearBasket');
				}
			});
		},
	},
	watch: {
		goToId() {
			if (this.goToId) {
				this.goToElement();
			}
		},
	},
	created() {
	}
};
</script>

<style scoped>
.table--product-prices {
	padding: 0;
	margin: 2px 0;
	text-align: right;
	border-radius: 5px;
}

.table--product-prices li {
	list-style: none;
	font-size: 12px;
}

.table--product-prices li .price {
	display: inline-block;
	font-size: 12px;
	font-weight: 400;
}

.table--product-prices li:last-child {
	border-bottom: none;
}

.table--product-prices li.active {
	display: inline-flex;
}

.table--product-prices li.active .price {
	color: #fff;
	background-color: #4caf50;
	padding: 3px;
	margin-left: 5px;
	font-weight: 300;
	line-height: 1.3;
	border-radius: .25rem;
}
</style>