<template>
	<v-dialog v-model="dialog" width="1024" content-class="dialog--default dialog--summary">
		<template v-slot:activator="{ on }">
			<slot name="activator_section" v-on="on" />
		</template>

		<v-card color="white">
			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn outlined @click="dialog = false">
					HIDE
				</v-btn>
				<v-btn color="error" class="ma-2 white--text" @click="orderNow">
					ORDER NOW
				</v-btn>
			</v-card-title>

			<v-divider />

			<v-card-text class="mt-2 pb-0">
				<v-data-table :headers="headers" :items="products" item-key="name" class="text-body-1"
					:items-per-page="-1" hide-default-footer mobile-breakpoint="0">
					<template v-slot:item="{ item, headers }">
						<tr
							v-if="item.type === 'category' && products[item.index + 1] && products[item.index + 1].type === 'product'">
							<td :colspan="headers.length" :class="`id-${item.id}`">
								<div class="text-h6" v-html="item.name" />
							</td>

						<tr v-if="item.type === 'product'">
							<td>
								<v-sheet min-width="100" width="100%" color="transparent"
									class="font-weight-500 black--text d-flex">
									{{ item.name }}
								</v-sheet>
							</td>
							<td>
								<v-sheet color="transparent" class="font-weight-500 text-no-wrap text-right">
									<span
										:style="item.retailPrice.promotional_price ? 'text-decoration: line-through; margin-right: 8px' : ''">
										{{ item.retailPrice.price / 100 | euro }}
									</span>

									<span v-if="item.retailPrice.promotional_price">
										{{ item.retailPrice.promotional_price / 100 | euro }}
									</span>
								</v-sheet>
							</td>
							<td>
								<v-sheet color="transparent" class="">
									<ul class="table--product-prices">
										<li v-for="(p, index) in item.prices" :key="index" :class="{
											active: index === activeDl
										}" class="text-no-wrap">
											{{
												index === activeDl
													? 'DL#' + index
													: ''
											}}

											<div class="price">
												<span
													:style="p.promotional_price ? 'text-decoration: line-through; margin-right: 8px' : ''">
													{{ p.price / 100 | euro }}
												</span>

												<span v-if="p.promotional_price">
													{{ p.promotional_price / 100 | euro }}
												</span>
											</div>
										</li>
									</ul>
								</v-sheet>
							</td>
							<td>
								<v-sheet width="116" color="transparent" class="">
									<block-product-quantity :item="item" :key="`${item.id}-${item.total}`" />
								</v-sheet>
							</td>
							<td class="text-right">
								<v-sheet width="90" color="transparent" class="">
									<div class="font-weight-500 text-no-wrap text-right">
										{{
											item.total / 100 | euro
										}}
									</div>
								</v-sheet>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-card-text>
			<v-divider />
			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn outlined @click="dialog = false">
					HIDE
				</v-btn>
				<v-btn color="error" class="ma-2 white--text" @click="orderNow">
					ORDER NOW
				</v-btn>
			</v-card-title>
		</v-card>
	</v-dialog>
</template>
<script>

import BlockProductQuantity from "@/views/Dashboard/Blocks/BlockProductQuantity";

export default {
	name: 'DialogSummary',
	components: { BlockProductQuantity },
	computed: {
		products() {
			return this.$store.getters['DASHBOARD_STORE/PRODUCTS_FOR_SUMMARY']
				.map((item, index) => {
					return {
						...item,
						index: index,
					}
				});
		},

		activeDl() {
			return this.$store.getters['DASHBOARD_STORE/ACTIVE_DL'];
		},


	},
	methods: {
		showDialog() {
			this.dialog = true;
		},

		orderNow() {
			this.$gtag.event(
				'Custom',
				{
					'klik': 'order_form-summary-order_now',
				}
			)
			this.$store.dispatch('DASHBOARD_STORE/updateBasket')
				.then(() => {
					this.$router.push({ name: 'BasketIndex' })
				})
		},
	},
	data: () => ({
		dialog: false,

		headers: [
			{
				text: 'Product', value: 'name', sortable: false, align: 'left'
			},
			{
				text: 'Retail Price (23% tax incl.)', value: 'retail', sortable: false, align: 'right'
			},
			{
				text: 'Your Price (net)', value: 'your', sortable: false, align: 'right',
			},
			{
				text: 'Quantity', value: 'quantity', sortable: false, width: 140, align: 'center',
			},
			{
				text: 'Total', value: 'total', sortable: false, width: 120, align: 'right',
			},
		]
	}),
	mounted() {
	}
};
</script>
